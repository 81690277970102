
let context = null
let firebaseApp = null

const setContext = pcontext => {
  context = pcontext
  firebaseApp = context.firebaseApp
}

const sendRfp = values => {
  if (!context) throw Error("Use setContext(context) to setup context first")
  if (!firebaseApp)
    throw Error("firebaseApp should not be emplty in the Context")
  return firebaseApp.addRFP(values)
  // return testModel.sendRfp(values)
}

const model = {
  sendRfp,
  setContext,
}

export default model
