
// Production
const firebaseConfigProd = {
  apiKey: "AIzaSyCReEq_Iyp7pqBPOTCTFR81IGsUPcmCt_Q",
  authDomain: "omnigon-site.firebaseapp.com",
  databaseURL: "https://omnigon-site.firebaseio.com",
  projectId: "omnigon-site",
  storageBucket: "omnigon-site.appspot.com",
  messagingSenderId: "705016311406",
  appId: "1:705016311406:web:6933519ddcc8eb631f007f",
  measurementId: "G-G73G82YGX6",
}

// Staging
const firebaseConfigStaging = {
  apiKey: "AIzaSyAgfZIdVb0y1FOtPTtEy1m1qi9AEzZh5Vw",
  authDomain: "omnigon-staging.firebaseapp.com",
  projectId: "omnigon-staging",
  storageBucket: "omnigon-staging.appspot.com",
  messagingSenderId: "533643127688",
  appId: "1:533643127688:web:2ef4aa33162c4e141ae83d",
  measurementId: "G-DT0DWC5NX7"
};


const firebaseUse=process.env.firebaseUse
const firebaseConfig = firebaseUse==='stage'? firebaseConfigStaging: firebaseConfigProd

export const firebaseConfigs = { prod: firebaseConfigProd, stage: firebaseConfigStaging }
export default firebaseConfig
