import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import firebaseConfig from "./firebase-config"

// firestorePort NOT empty means development environment
const firestorePort = process.env.firestorePort

class Firebase {
  constructor() {
    if (firestorePort) {
      this.firebaseApp = firebase.initializeApp(firebaseConfig)
      this.firestore = firebase.firestore()
      this.firestore.settings({
        host: `127.0.0.1:${firestorePort}`,
        ssl: false,
        merge: true
      }, { merge: true })
      this.auth = firebase.auth
    } else {
      this.firebaseApp = firebase.initializeApp(firebaseConfig)
      this.firestore = firebase.firestore()
      this.auth = firebase.auth
    }
  }

  getCurrentUser = () => {
    const currentUser = this.auth().currentUser
    console.info(currentUser)
    return new Promise((resolve, reject) => {
      if (currentUser) {
        if (!currentUser.email) reject("Email is undefined")
        if (!currentUser.uid) reject("uid is undefined")
        resolve(currentUser)
      }
      reject("User is undefined")
    })
  }

  addCeoContact = values => {
    // console.info("addCeoContact")
    // const timestamp = this.firestore.FieldValue.serverTimestamp()
    return this.getCurrentUser().then(currentUser => {
      const userId = currentUser.uid
      const vals = {
        ...values,
        userId,
        // timestamp,
      }
      console.info("sendContactRequest [05] sendContactRequest values:", vals)
      return this.firestore
        .collection("ceo-contact")
        .add(vals)
    })
  }

  addRFP = values => {
    // console.info("addCeoContact")
    // const timestamp = this.firestore.FieldValue.serverTimestamp()
    return this.getCurrentUser().then(currentUser => {
      const userId = currentUser.uid
      const vals = {
        ...values,
        userId,
        // timestamp,
      }
      // console.info("sendContactRequest [05] sendContactRequest values:", vals)
      return this.firestore
        .collection("rfp")
        .add(vals)
    })
  }
}

export default Firebase
