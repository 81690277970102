import { initializeApp } from "firebase/app"
import { getFirestore, collection, addDoc, serverTimestamp, connectFirestoreEmulator } from "firebase/firestore"
import { getAuth } from "firebase/auth";

import firebaseConfig from "./firebase-config"

export function initFirebase() {
  const firebase = initializeApp(firebaseConfig)

  // firestorePort NOT empty means development environment
  const firestorePort = process.env.firestorePort
  if (firestorePort) {
    const db = getFirestore()
    connectFirestoreEmulator(db, '127.0.0.1', firestorePort);
  }
  return { firebase }
}

export function addCeoContact(values) {
  const vals = {
    ...values,
    userId: getCurrentUser().uid,
    timestamp: serverTimestamp(),
  }

  const db = getFirestore();
  return addDoc(collection(db, "ceo-contact"), vals);
}

function getCurrentUser() {
  const auth = getAuth();
  const currentUser = auth.currentUser
  return currentUser
}
