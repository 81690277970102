import React, { useContext, useReducer, useEffect, useState } from "react"
import Firebase from "./firebase/firebase"
import { initFirebase } from "./firebase/new-firebase"

// import model from "./test-model"
// import model from "./http-model"
import model from "./firestore-model"

const FirebaseContext = React.createContext({})
const NewFirebaseContext = React.createContext({})
const SiteContext = React.createContext({})

const initialState = {
  user: null,
}

const contextReducer = (state, action) => {
  switch (action.type) {
    case "setUser": {
      if (!action.user)
        new Error(
          `Property 'user' has to be defined for the action '${action.type}'`
        )
      return { ...state, user: action.user }
    }
    case "resetUser":
      return { ...initialState }
    default:
      throw new Error(`Unexpected action ${action.type}`)
  }
}

const SiteContextProvider = ({ children }) => {
  const siteContextReducer = useReducer(contextReducer, initialState)
  const [, setSiteContext] = siteContextReducer
  const { firebaseApp } = useFirebaseContext()

  useEffect(() => {
    const unregisterAuthObserver = firebaseApp
      .auth()
      .onAuthStateChanged(user => {
        if (user) {
          setSiteContext({
            type: "setUser",
            user,
            /*             user: {
              displayName: user.displayName,
              email: user.email,
              uid: user.uid,
              phoneNumber: user.phoneNumber,
            }, */
          })
        } else {
          setSiteContext({ type: "resetUser" })
        }
      })
    return () => {
      unregisterAuthObserver()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseApp])

  if (model.setContext) {
    model.setContext({ siteContextReducer, firebaseApp })
  }

  return (
    <SiteContext.Provider
      value={{
        siteContextReducer,
        model,
        firebaseApp,
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}

const FirebaseContextProvider = ({ children }) => {
  const firebaseApp = new Firebase()
  return (
    <FirebaseContext.Provider value={{ firebaseApp }}>
      {children}
    </FirebaseContext.Provider>
  )
}

const NewFirebaseContextProvider = ({ children }) => {
  const [newFirebaseApp, setNewFirebaseApp] = useState()

  useEffect(() => {
    setNewFirebaseApp(initFirebase())
  }, [])

  return (
    <NewFirebaseContext.Provider value={{ newFirebaseApp }}>
      {children}
    </NewFirebaseContext.Provider>
  )
}

export const wrapWithAll = ({ element }) => {
  return (
    <NewFirebaseContextProvider>
      <FirebaseContextProvider>
        <SiteContextProvider>{element}</SiteContextProvider>
      </FirebaseContextProvider>
    </NewFirebaseContextProvider>

  )
}

export const useFirebaseContext = () => useContext(FirebaseContext)

export const useSiteContext = () => {
  return useContext(SiteContext)
}
